<template>
  <div>
    <div style="position:absolute;width: 45px;height: 45px;" @click="slip()"><img loading="lazy" src="@/assets/slipleft.png"></div>

    <div class="ViewTitle">Personal Center</div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Basic Information" name="first">
        <div style="height: 100vh">
          <div >
            <div class="boxEdit">
              <!--      个人信息-->
              <div class="box1">
                <div style="padding: 17px 18px 15px 18px;">
                  <div>
                    <div style="display: flex;justify-content:space-between;border-bottom: 1px solid #e1e1e1">
                      <div style="height: 45px;">
                        <div class="userName1" style="margin: 5px 0 0 12px;">Avatar</div>
                      </div>
                      <div class="Photo">
                        <el-upload
                            class="avatar-uploader boxCardUploadImg"
                            :action="baseURL + '/common/upload'"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :on-error="handleAvatarError"
                            accept=".png,.jpg,.jpeg"
                        >
                          <img
                              :src="form.avatar!==null ? baseURL + form.avatar : avator"
                              class="avatar"
                          />
                          <!--                  <div class="wrap">-->
                          <!--                    <div>上传头像</div>-->
                          <!--                  </div>-->
                        </el-upload>
                      </div>
                    </div>
                    <div class="information">
                      <!--            姓名      -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Nickname</div>
                        <div class="titleInput" style="width: 70%;position: absolute;right:10px">
                          <el-input
                              v-model="form.gptsUserName"
                              max-length="20"
                              placeholder="Please enter your nickname"
                          ></el-input>
                        </div>
                      </div>
                      <!--              昵称  -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Username</div>
                        <div class="titleInput" style="width: 70%;position: absolute;right:10px">
                          <el-input
                              v-model="form.userName"
                              max-length="20"
                              placeholder="Please enter your name"
                          ></el-input>
                        </div>
                      </div>
                      <!--              昵称  -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Company name</div>
                        <div class="titleInput" style="width: 60%;position: absolute;right:10px">
                          <el-input
                              v-model="form.companyName"
                              max-length="20"
                              placeholder="Please enter the name of business"
                          ></el-input>
                        </div>
                      </div>

                    </div>


                    <div style="display: flex; ">
                      <el-button class="cancleStyle" @click="cancleSubmit()"
                      >Cancel</el-button
                      >
                      <div class="confirmStyle" @click="confirmSubmit1()">Confirm</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Personal Detail Information" name="second">
        <div style="height: 100vh">
          <div >
            <div class="boxEdit">
              <!--      个人信息-->
              <div class="box1">
                <div style="padding: 17px 18px 15px 18px;">
                  <div>
                    <div class="information">
                      <!--              企业类型    -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Type of enterprise</div>
                        <div class="titleInput" style="width: 60%;position: absolute;right:-2px;margin-top: -8px">
                          <el-cascader
                              :options="industry"
                              v-model="form.companyIndustry"
                              style="width: 100%"
                              placeholder="Please select the type of business you belong to"
                          ></el-cascader>
                        </div>
                      </div>
                      <!--              昵称  -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Position</div>
                        <div class="titleInput" style="width: 70%;position: absolute;right:10px">
                          <el-select v-model="form.job" style="width: 100%" placeholder="Please select your position style">
                            <el-option
                                v-for="item in jobStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <!--               城市  -->
                      <div style="position:relative;display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Business address</div>
                        <div class="titleInput" style="width: 60%;position: absolute;right:-20px;bottom:7px;text-align: right">
                          <el-select v-model="form.country"    @change="countryChange()" style="width: 50%" placeholder="area">
                            <el-option

                                v-for="item in gloable"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>

                        </div>
                      </div>
                      <!--               城市  -->
                      <div style="position:relative;display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Detail address</div>
                        <div class="titleInput" style="width: 70%;position: absolute;right:-10px;bottom:7px;text-align: right">
                          <el-input
                              @input="areaChange"
                              v-model="form.area"
                              max-length="20"
                              placeholder="The detail address of company"
                          ></el-input>

                        </div>
                      </div>
                      <!--               电话   -->
                      <div style="display: flex;height:40px;border-bottom: 1px solid #e1e1e1">
                        <div class="star"><img src="@/assets/star.png"></div>
                        <div class="title" style="margin-left: 0">Phone</div>
                        <div class="titleInput" style="width: 60%;position: absolute;right:24px">
                          <el-input
                              v-model="form.gptsUserPhone"
                              disabled
                              max-length="20"
                              placeholder="Please enter the phone number"
                          ></el-input>
                        </div>
                      </div>

                    </div>


                    <div style="display: flex; ">
                      <el-button class="cancleStyle" @click="cancleSubmit()"
                      >Cancel</el-button
                      >
                      <div class="confirmStyle" @click="confirmSubmit2()">Confirm</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </el-tab-pane>

    </el-tabs>


  </div>


</template>
<script>
// import recruitUpload from "@/views/candidate/recruitUpload.vue";
import {proCityList} from "@/api/city";
// import recruitUpload from "@/views/candidate/recruitUpload.vue";
export default {
  name: "Info",
  components: { },
  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      activeName:'first',
      provinces: proCityList,
      baseURL: this.$utils.baseURL,
      avator: require("@/assets/user.jpg"),
      form:[],
      industry:[],
      input:'',
      userInfo:localStorage.getItem("userInfo"),
      userId:localStorage.getItem("userId"),
      jobStatus:[
        { value: '公司负责人',
          label: 'Company leader'},
        { value: '采购人员',
          label: 'Procurement'},
        { value: '销售人员',
          label: 'Sale'},
        { value: '技术人员',
          label: 'Technology'},
        { value: '其他人员',
          label: 'Other'},

      ],
      gloable:[
        { value: '中国',
          label: 'China'},
        { value: '欧洲',
          label: 'Europe'},
        { value: '南美',
          label: 'South America'},
        { value: '北美',
          label: 'North America'},
        { value: '中亚',
          label: 'Central Asia'},
        { value: '澳洲',
          label: 'Australia'},
        { value: '东南亚',
          label: 'Southeast Asia'},
        { value: '中东',
          label: 'Middle East'},
        { value: '其他地区',
          label: 'Other regions'},
      ],


    }

  },
  created() {
    this.jobCitythis()
    this.getUser()
    this.getIndustry()
  },

  methods: {
    countryChange(){
      console.log(this.form.country)
      this.$forceUpdate()
    },
    areaChange(){
      console.log(this.form.area)
      this.$forceUpdate()
    },
    slip(){
      this.$router.go(-1)
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Upload picture size cannot exceed 2MB!");
      }
      return isLt2M;
    },
    // 文件上传失败（http）
    handleAvatarError() {
      this.$message.error("Upload failed! (http failed)");
    },
    jobCitythis(){
      this.provinces=this.transformCityData(this.provinces);
    },
    transformCityData(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
          children: item.children ? this.transformCityDataChild(item.children) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformCityDataChild(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    cancleSubmit() {
      this.getUser()
    },
    confirmSubmit1() {
      if (!this.form.gptsUserName) {
        this.$message.error("Please enter your nickname");
        return false;
      }
      if (!this.form.userName) {
        this.$message.error("Please enter your user name");
        return false;
      }
      if (!this.form.companyName) {
        this.$message.error("Please enter your company name");
        return false;
      }

      this.$axios
          .put("/info/gpts_user", {
            companyName: this.form.companyName,
            gptsUserEmail: this.form.gptsUserEmail,
            gptsUserId: this.userId,
            gptsUserName: this.form.gptsUserName,
            avatar:this.form.avatar,
            gptsUserPhone: this.form.gptsUserPhone,
            job:this.form.job,
            userName: this.form.userName
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: 'Your personal information has been submitted successfully',
              type: 'success'
            });

            this.activeName='second'
            this.getUser()
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              showClose: true,
              message: 'The submission failed due to network reasons. Please try again later',
              type: 'success'
            });
          });

    },
    confirmSubmit2() {
      if (!this.form.companyIndustry) {
        this.$message.error("Please enter the business type of your company");
        return false;
      }


      if (!this.form.job) {
        this.$message.error("Please select your position style");
        return false;
      }
      if (!this.form.country) {
        this.$message.error("Please select the area of your company");
        return false;
      }
      if (!this.form.area) {
        this.$message.error("Please enter the address of your company");
        return false;
      }
      const cityNow=this.form.country+"-"+this.form.area
      const companyIndustryNow=this.form.companyIndustry[0]+"-"+this.form.companyIndustry[1]
      if (!cityNow) {
        this.$message.error("Please enter the address of your company");
        return false;
      }
      this.$axios
          .put("/info/gpts_user", {
            city: cityNow,
            companyIndustry: companyIndustryNow,
            companyName: this.form.companyName,
            gptsUserEmail: this.form.gptsUserEmail,
            gptsUserId: this.userId,
            gptsUserName: this.form.gptsUserName,
            avatar:this.form.avatar,
            gptsUserPhone: this.form.gptsUserPhone,
            job:this.form.job,
            userName: this.form.userName
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            this.$message({
              showClose: true,
              message: 'Your personal information has been submitted successfully',
              type: 'success'
            });
            this.getUser()
            this.$router.push("/")
          })
          .catch((err) => {
            console.log(err);
            this.$message({
              showClose: true,
              message: 'The submission failed due to network reasons. Please try again later',
              type: 'success'
            });
          });

    },

    getIndustry(){
      this.$axios
          .get("/info/industry/list",{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.industry = this.transformIndustryData(res.data.data)

            // console.log("--------ren",this.industry)

          })
          .catch((err) => {
            console.log(err);
          });
    },
    transformIndustryData(data) {
      // console.log(data)
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContent,
          label: item.industryContent,
          children: item.child ? this.transformIndustryDataChild(item.child) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformIndustryDataChild(data) {
      // console.log(data)
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContent,
          label: item.industryContent,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    getUser() {
      this.$axios
          .get("/info/gpts_user/" + this.userId)
          .then((res) => {
            this.form = res.data.data;
            var str = this.form.city
            var parts = str.split("-");
            this.form.country=parts[0]
            this.form.area=parts[1]
            // this.form.city[1]=parts[1]
            var strcompanyIndustry = this.form.companyIndustry
            var partscompanyIndustry = strcompanyIndustry.split("-");
            this.form.companyIndustry=partscompanyIndustry

            console.log("--------ren",this.form)

          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>
<style scoped lang="scss">
.ViewTitle {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  padding: 0 40px;
  text-align: center;
  justify-content: center;
}
.box {
  width: 100vw;
  background: #fff;
  .box1{
    ::v-deep .el-input__prefix {
      right: 250px;
      transition: all .3s;
    }
    height: fit-content;
    width: 100vw;
    padding: 17px 18px 5px 18px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .cancleStyle{
      margin-top: 46px;
      width: 128px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color:  #BF0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle{

      margin-top: 46px;
      margin-left: 20px;
      cursor: pointer;
      border: none;
      width: 128px;
      height: 44px;
      background-color: #BF0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
      position: relative;
    }

    ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-input__suffix {
      height: 100%;
      left: 240px;
      transition: all .3s;
      pointer-events: none;
    }

    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 48px;
      row-gap: 20px;
      //padding-top: 32px;
      .star{
        width: 7px;
        height:7px;
        margin-top:0;
        margin-right: 5px;
      }
      .title{
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
        -webkit-background-clip: text;
      }
      .titleInput{
        margin-top: 12px;
        .sexStyle1{
          width: 145px;
          height: 44px;
          background: #F8E9ECFF;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #BF0022;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #BF0022;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .sexStyle2{
          width: 145px;
          height: 44px;
          background: #FDFDFDFF;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border:none;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: gray;
          cursor: pointer;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .editStyle{
      position: absolute;
      top:0;
      display: flex;
      right:0;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      cursor: pointer;
      .editLogo{
        width: 13px;
        height: 12px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .editTitle{
        width: 28px;
        height: 16px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #BF0022;
        line-height: 16px;
        margin-left:7px ;
      }
    }

    .Photo{
      width: 80px;
      height: 70px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .userName{
      height: 24px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      color: #333333;
      line-height: 24px;

    }
    .userName1{
      margin-top: 9px;
      width: 80px;
      height: 24px;
      font-size: 20px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #BF0022;
      line-height: 24px;
      -webkit-background-clip: text;

    }
    .userName2{
      margin-top: 14px;
      width: 720px;
      color: gray;
      height: 24px;
      opacity: 1;

    }
    .userSex{
      height: 16px;
      font-size: 12px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-right: 6px;
    }
    .iconStyle{
      color:gray;
      width: 14px;
      height: 14px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }


  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input__suffix {
    height: 100%;
    left: 240px;
    transition: all .3s;
    pointer-events: none;
  }
  .ViewTitle {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 28px;
      background: var(--main-color);
      margin-left: 100px;
      cursor: pointer;
      margin-top: 56px;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar,
  .boxCardUploadImg,
  .box .avatar-uploader-icon,
  .el-upload {
    width: 60px !important;
    height: 60px !important;
    display: block !important;
    border-radius: 36px;
  }
  .el-upload {
    position: relative;
    overflow: hidden;
  }

  .boxCardUploadImg .el-upload div.wrap {
    // display: none;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    div {
      width: 72px;
      height: 24px;
      line-height: 20px;
      color: #ffffff;
      font-size: 10px;
      cursor: pointer;
      background: rgba($color: #000000, $alpha: 0.4);
      overflow: hidden !important;
    }
  }
}
.boxEdit {
  overflow-x: hidden;
  width: 100vw;
  background: #fff;
  .box1{
    ::v-deep .el-input__prefix {
      //right: -200px;
      transition: all .3s;
    }
    ::v-deep .el-input__icon{
      margin-top: -4px;
      line-height: 10px;
    }
    height: fit-content;
    width: 100vw;
    //padding: 17px 18px 5px 18px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .cancleStyle{
      margin-top: 46px;
      width: 50%;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color:  #BF0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle{

      margin-top: 46px;
      margin-left: 20px;
      cursor: pointer;
      border: none;
      width: 50%;
      height: 44px;
      background-color: #BF0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
      position: relative;
    }

    ::v-deep .el-input--prefix .el-input__inner {
      padding:0 15px;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-input__suffix {
      height: 100%;
      left: 230px;
      transition: all .3s;
      pointer-events: none;

    }
    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 30px;
      }

    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 15px;
      padding-top: 20px;
      .star{
        width: 7px;
        height:7px;
        margin-top:0;
        margin-right: 5px;
      }
      .title{
        height: fit-content;
        width: fit-content;
        font-size: 14px;
        display: flex;
        margin: 5px 0 0 12px;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
        -webkit-background-clip: text;
      }
      .titleInput{
        ::v-deep .el-input__inner{
          margin-top: -8px;
          text-align: right;
        }
        .sexStyle1{
          width: 50px;
          height: 25px;
          background: #F8E9ECFF;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #BF0022;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #BF0022;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .sexStyle2{
          width: 50px;
          height: 25px;
          background: #FDFDFDFF;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #f5f5f5;
          opacity: 1;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: gray;
          cursor: pointer;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .editStyle{
      position: absolute;
      top:0;
      display: flex;
      right:0;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      cursor: pointer;
      .editLogo{
        width: 13px;
        height: 12px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .editTitle{
        width: 28px;
        height: 16px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #BF0022;
        line-height: 16px;
        margin-left:7px ;
      }
    }

    .Photo{
      width: 15%;
      height: 25px;
      text-align: right;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .userName{
      height: 24px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      color: #333333;
      line-height: 24px;

    }
    .userName1{
      margin-top: 9px;
      width:fit-content;
      height: 24px;
      font-size: 14px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      line-height: 24px;
      -webkit-background-clip: text;

    }
    .userName2{
      margin-top: 14px;
      width: 720px;
      color: gray;
      height: 24px;
      opacity: 1;

    }
    .userSex{
      height: 16px;
      font-size: 12px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-right: 6px;
    }
    .iconStyle{
      color:gray;
      width: 14px;
      height: 14px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .box2{
    position: relative;
    background: #FFFFFF;
    //box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    opacity: 1;
    height: fit-content;
    //padding: 15px 0 50px 0 ;
    ::v-deep .el-input__prefix {
      //right: -200px;
      transition: all .3s;
    }
    ::v-deep .el-input__icon{

      line-height: 10px;
    }
    ::v-deep .el-input__inner {
      text-align: right;
    }

    .userSex{
      height: 16px;
      font-size: 14px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-left: 6px;
    }
    .iconStyle{
      color:gray;
      width: 14px;
      height: 14px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .cancleStyle{
      margin-top: 20px;
      width: 50%;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color:  #BF0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle{

      margin-top: 20px;
      margin-left: 20px;
      cursor: pointer;
      border: none;
      width: 50%;
      height: 44px;
      background-color: #BF0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 15px;
      padding-top: 20px;
    }
    .title{
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
      -webkit-background-clip: text;
    }
    .titleInput{
      margin-top: -15px;
      .sexStyle1{
        margin-top: 10px;
        width: 55px;
        height: 25px;
        background: #F8E9ECFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #BF0022;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: #BF0022;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .sexStyle2{
        margin-top: 10px;
        width: 55px;
        height: 25px;border: 1px solid #d7d6d6;
        background: #FDFDFDFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 16px;
        font-family: Roboto,sans-serif;
        font-weight: 400;
        color: gray;
        cursor: pointer;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .addStyle{
      cursor: pointer;
      position: absolute;
      display: flex;
      bottom: 0;
      background: #3162c3;
      width: 100vw;
      padding: 10px;
      justify-content: center;
      text-align: center;

    }
    .addStyle2{
      position: absolute;
      display: flex;
      right: 16px;
    }
    .addStyle1{
      position: absolute;
      display: flex;
      right:15px;
    }
    .workStyle2{
      position: absolute;
      display: flex;
      right: 16px;
      top:19px;
    }
    .workStyle1{
      position: absolute;
      display: flex;
      right: 15px;
      top:19px;

    }
    .addLogo{
      width: 16px;
      height: 16px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .addTitle{
      width: fit-content;
      height: 16px;
      font-size: 12px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: white;
      line-height: 16px;
      margin-left:7px ;
    }
    .jobName{
      width: fit-content;
      height: 24px;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
      padding: 0 0 0 15px;
      border-left: #3163c5 3px solid;
    }
    .jobNameLittle{
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,sans-serif;

      color: #222222;
      line-height: 24px;
      padding: 10px 15px 0 15px;

    }
    .jobName1{
      width: 80px;
      height: 24px;
      font-size: 20px;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }
    .jobClass{
      cursor: pointer;
      margin-top: 10px;
      width: 100%;
      list-style: none;
      .jobStyle{
        position: relative;
        display: flex;
        height: fit-content;
        padding:8px 16px ;
        width: 100%;

      }
      .jobStyle1{
        position: relative;
        height: fit-content;
        width: 100%;
        margin-top: 12px;
      }
      .actions {
        opacity: 15;
        //visibility: hidden;
        //transition: opacity 0.3s ease, visibility 0.3s ease;
      }

      .workName{
        height: 24px;
        font-size: 14px;
        font-family: Roboto,sans-serif;
        font-weight: 500;
        color: #222222;
        line-height: 24px;
      }
      .span{
        width: 1px;
        height: 12px;
        background: #CCCCCC;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 6px 10px;
      }



    }
    .workClass{
      cursor: pointer;
      width: 100vw;
      list-style: none;
      .workStyle{
        margin-top:10px;
        position: relative;
        width: 100%;
        height:auto;
        padding: 10px 0 10px 0;
        //background:  #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        .workName{
          height: 24px;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .span{
          width: 1px;
          height: 12px;
          background: #CCCCCC;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          margin: 6px 10px;
        }
        .workCompanyName{
          margin-top:4px;
          height: 16px;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .workTime{
          height:22px;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          padding-left: 6px;
          margin-left: 6px;
          //border-left: 1px solid gray;

        }
        .industry{
          margin-top: 4px;
          height: 16px;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .indus{
          height: fit-content;
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 70%;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        } .indusLunwen{
            height: fit-content;
            word-wrap: break-word; /* 允许在单词内换行 */
            width:70%;
            font-size: 12px;
            font-family: Roboto,sans-serif;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
        .industry2{
          width: fit-content;
          height: 28px;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
        .indusContent{
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 100%;
          height: auto;
          font-size: 14px;
          font-family: Roboto,sans-serif;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
      .workActions {
        opacity: 156;
        display: block;
        //visibility: hidden;
        //transition: opacity 0.3s ease, visibility 0.3s ease;
      }

    }

  }
  .box3{
    position: relative;
    background: #FFFFFF;
    border-top: 1px solid #f8f8f8;
    //box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    opacity: 1;
    height: 50px;
    padding: 15px 14px 50px 0 ;
  }

  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input__suffix {
    height: 100%;
    left: 240px;
    transition: all .3s;
    pointer-events: none;
  }
  .ViewTitle {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 28px;
      background: var(--main-color);
      margin-left: 100px;
      cursor: pointer;
      margin-top: 56px;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar,
  .boxCardUploadImg,
  .box .avatar-uploader-icon,
  .el-upload {
    width: 35px !important;
    height: 35px !important;
    display: block !important;
    border-radius: 36px;
  }
  .el-upload {
    position: relative;
    overflow: hidden;
  }

  .boxCardUploadImg .el-upload div.wrap {
    // display: none;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    div {
      width: 72px;
      height: 24px;
      line-height: 20px;
      color: #ffffff;
      font-size: 10px;
      cursor: pointer;
      background: rgba($color: #000000, $alpha: 0.4);
      overflow: hidden !important;
    }
  }
}
.upload{

}
:deep .el-tabs__nav-scroll{
  padding:0 5vw;
  width: 90vw;
}
::v-deep .el-tabs__header{
  margin-bottom: 0;
}
:deep .el-tabs__item{
  height: 45px;
  width: 40vw;
  font-size: 14px;
  text-align: center;
}
::v-deep .el-tabs__item.is-active{
  //font-weight: bold !important;
  width: 40vw;
  font-size: 14px;
  text-align: center;
}
::v-deep {
  .contain {
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      line-height: 50px;
      height: 50px;
      font-size: 16px;
      background: inherit;
      color: #333;
    }
    .el-input__inner::placeholder {
      color: #bbbbbb;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #dcdfe6;
    }
    .el-form-item {
      width: fit-content;
      margin-bottom: 36px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-form-item__content {
      width: 400px;
      background: #f5f5f7;
      border-radius: 4px;
    }
    .avatorBox .el-form-item__content {
      width: auto;
      background: none;
      border-radius: 100%;
    }
    .el-input__prefix {
      left: auto;
      right: 5px;
      .el-input__icon.el-icon-date {
        transform: rotate(180deg);
        font-size: 20px;
        &:before {
          content: "\e6e1";
        }
      }
    }
    .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    .el-select__caret {
      font-size: 20px;
    }
  }
}
input {
  border: none;
  outline: none;
}
.box .avatar-uploader-icon {
  width: 72px !important;
  height: 72px !important;
  display: block !important;
  border-radius: 36px;
  line-height: 72px;
}
</style>
